import React from 'react';
import SEO from '../components/seo';
import Layout from '../containers/layout/layout';
import Hero from '../components/hero';
import { graphql } from 'gatsby';

import loadable from '@loadable/component'
const ContactForm = loadable(() => import('../components/contact-form'))

// import ContactForm from '../components/contact-form';

export const contactPageData = graphql`
  query contactPageQuery {
    sanityContact {
      hero {
        title
        subtitle
        image {
          alt
          asset {
            fluid(maxWidth: 1920, maxHeight: 750) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      title
      subhead
      copy
      openGraph {
        title
        description
        image {
          asset {
            url
          }
        }
      }
      formGuid
    }
    sanityGlobalSettings {
      hspid
      hapikey
    }
  }
`;

const ContactPage = (props) => {
  const { data } = props;

  const pageData = data.sanityContact;
  const hero = pageData.hero;
  const openGraph = pageData.openGraph;
  const {
    sanityGlobalSettings: { hspid },
    sanityGlobalSettings: { hapikey },
    sanityContact: { formGuid },
  } = data;

  return (
    <Layout headerLayout={2} pageType={1}>
      <SEO
        title={openGraph?.title}
        description={openGraph?.description}
        image={openGraph?.image?.asset?.url}
        pathname={props.location.pathname}
      />
      <Hero
        title={hero.title}
        subhead={hero.subtitle}
        image={hero.image.asset.fluid}
        alt={hero.image.alt}
      />
      {/*<ContactForm
        title={pageData.title}
        subhead={pageData.subhead}
        copy={pageData.copy}
        image={hero.image.asset.fluid}
        hspid={hspid}
        hapikey={hapikey}
        formguid={formGuid}
      />*/}
    </Layout>
  );
};

export default ContactPage;
